import { useStaticQuery, graphql } from "gatsby";

export const useExternalLinks = () => {
  const { allExternalLink } = useStaticQuery(graphql`
    query ExternalLinksQuery {
      allExternalLink {
        edges {
          node {
            url
            title
            rel
          }
        }
      }
    }
  `);
  return allExternalLink.edges.map(({ node }: any) => node);
};
