import React from "react";
import styled from "@emotion/styled";
import { useExternalLinks } from "../hooks/useExternalLinks";
import { LocaleContext } from "../localeContext";
import { TranslationContext } from "../translationsContext";

interface ImagePropsI {
  image: {
    title: string;
    description: string;
    type: string;
    body: {
      children: [
        {
          __typename: string;
          image: {
            alt: string;
            image: {
              fluid: {
                src: string;
              };
            };
          };
        }
      ];
    };
  };
}

const ImageContainer = styled.div`
  transition: 1s ease;
  position: relative;
  text-align: center;
`;

const Image = styled.img`
  max-width: 90%;
  @media (min-width: 800px) {
    max-width: 720px;
  }
`;

const LoginLink: any = styled.a`
  position: absolute;
  bottom: 0;
  right: 50%;
  color: #abf422;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: center;
  text-decoration: none;
  border-radius: 500px;
  background-color: #441152;
  border: solid 2px #ffc400;
  color: #ffc400;
  text-transform: uppercase;
  transform: translate(50%, 160%);
  padding: 10px 22px;
  margin: 50px auto;
  cursor: pointer;
  @media (min-width: 768px) {
    bottom: 20%;
    transform: translate(50%, 220%);
    font-size: 16px;
    padding: 14px 34px;
  }
`;

const PageImage = ({ image }: ImagePropsI) => {
  const contextData = React.useContext(LocaleContext);
  const translationsData = React.useContext(TranslationContext);
  const links = useExternalLinks();
  const affiliateLink = links.find((node: any) => node.title == "Affiliate");

  const startPlayingButtonText = translationsData.find(
    translation => translation.key === "START_PLAYING"
  );

  const logInHereButtonText = translationsData.find(
    translation => translation.key === "LOG_IN_HERE"
  );
  return (
    <ImageContainer>
      {image &&
        image.body.children.map(child => {
          if (child.__typename === "DinoBlock") {
            return null;
          }
          return (
            <div key={child.image.alt}>
              <Image
                src={child && child.image.image.fluid.src}
                alt={child && child.image.alt}
              />
              <LoginLink href={affiliateLink.url} rel={affiliateLink.rel}>
                {child.image.alt === "Cashback"
                  ? startPlayingButtonText?.description
                  : logInHereButtonText?.description}
              </LoginLink>
            </div>
          );
        })}
    </ImageContainer>
  );
};

export default PageImage;
